



























import { Component, Vue, Watch } from 'vue-property-decorator'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component
export default class AppAlert extends Vue {
	set show(value: boolean) {
		AlertModule.setShow(value)
	}

	get show() {
		return AlertModule.show
	}

	get message() {
		return AlertModule.message
	}

	@Watch('show')
	onShow(show: boolean) {
		show
			? document.addEventListener('keydown', this.atalhoDeSumir)
			: document.removeEventListener('keydown', this.atalhoDeSumir)
	}

	atalhoDeSumir(event: KeyboardEvent) {
		if (event.key !== 'Escape') return
		if (this.show) this.show = false
		event.preventDefault()
		event.stopPropagation()
	}
}

