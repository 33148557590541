









import { Vue, Component } from 'vue-property-decorator'
import { comoConheceu } from '@/models/Cadastro'

@Component
export default class SeletorDeCadastro extends Vue {
	searchInput = null

	itens = comoConheceu
}

